(() => {
  const sections = Array.from(document.querySelectorAll("body > .content > [class*=component-],body > .content > .pin-spacer > [class*=component-]"));
  sections.forEach(s => s.classList.add("pre-init"));

  //Turn off auto-scroll to previous position because it's interfering with panel initialization
  if ('scrollRestoration' in history) history.scrollRestoration = 'manual';

  const getActiveSection = () => {
    let s = sections.length - 1;
    let activeSection; 
    do {    
      activeSection = sections[s];
      s--;
    } while (s >= 0 && activeSection.getBoundingClientRect().y > (window.innerHeight / 3));
    return activeSection;
  }

  const initActiveSection = (section) => {
    section = section ? section : getActiveSection();
    const anchor = section.querySelector(`a[name]`);
    history.pushState({},'',`#${anchor ? anchor.name : 'top'}`);
    if (section.classList.contains("pre-init")) {
      setTimeout(() => {
        section.classList.add("init-transition");
        section.classList.remove("pre-init");
      }, 100);
    }
  }

  const setHandlers = () => {
    let scrollTimeout;
    window.addEventListener("scroll",(e) => {
      if (scrollTimeout) clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        initActiveSection();
      },100);
    });
  }

  const initPrep = () => {
    document.addEventListener("DOMContentLoaded",() => {

      if (location.hash && location.hash.length > 1) {
        const hash = location.hash.substring(1,location.hash.length);
        const anchor = document.querySelector(`a[name="${hash}"]`);
        if (anchor) {
          document.documentElement.scrollTop = anchor.getBoundingClientRect().top;
          if (anchor.name === "top") {
            init();
          } else {
            const section = anchor.parentElement;
            let resizeTimeout;
            const waitForReady = () => {
              if (resizeTimeout) clearTimeout(resizeTimeout);
              resizeTimeout = setTimeout(() => {
                window.removeEventListener("scroll",waitForReady);
                init(section);
              },500);
            }
            window.addEventListener("scroll",waitForReady);
          }
        } else {
          init();
        }
      } else {
        init();
      }
    })
  }

  const init = (section) => {
    initActiveSection(section);
    document.querySelector(".nav-jump").classList.add("initialized");
    setHandlers();
    window.dispatchEvent(new CustomEvent('appInitialized'));
  }

  initPrep();

})()